import React, { useState } from "react";
import styled from "styled-components";
import { numberWithPeriods } from "../../utils/numbers";
import packService from "../../utils/api/v1/packService";

// components
import Bold from "../Typography/Bold";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Flex from "../Containers/Flex";
import Modal from "@material-ui/core/Modal";
import ModalContainer from "../Containers/ModalContainer";
import { Link, navigate } from "gatsby";
import SButton from "../Buttons/SButton";
import { SpacedPaper, SectionHeader } from "../Containers/SpacedPaper";
import Wrapper from "../Containers/Wrapper";
import Typography from "@material-ui/core/Typography";

const PackDataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-template-rows: 1fr 1fr;
  padding: 2rem 2rem 0 2rem;
`;

const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr 4fr 5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  padding: 1rem 2rem 2rem 2rem;
`;

interface Props {
  id: string;
  packData: any;
  setError: any;
}

const PackData = (props: Props): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const deletePack = async (): Promise<void> => {
    setLoading(true);
    try {
      await packService.deletePack(props.id);
      navigate("/packs");
    } catch (err) {
      props.setError(err);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          document.getElementById("navbar").style.zIndex = "1999";
        }}
        style={{ height: "100%" }}
      >
        <ModalContainer>
          <Flex justify="center">
            <Wrapper variant="outlined">
              <h2>Agregar administrador</h2>
              <Typography>
                Esta acción no se puede deshacer. ¿Está seguro que desea
                eliminar el pack?
              </Typography>
              <Flex
                align="center"
                justify="center"
                margin="2rem 0rem 1rem 0rem"
              >
                <SButton
                  variant="outlined"
                  color="secondary"
                  disabled={loading}
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Cancelar
                </SButton>
                <SButton
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={deletePack}
                >
                  Eliminar
                </SButton>
              </Flex>
            </Wrapper>
          </Flex>
        </ModalContainer>
      </Modal>
      <SpacedPaper variant="outlined">
        <Flex direction="column">
          <SectionHeader>
            <Bold>Información del pack</Bold>
            <ButtonGroup variant="text">
              <Button
                color="secondary"
                onClick={() => {
                  setModal(true);
                }}
              >
                Eliminar pack
              </Button>
              <Link
                to={`/packs/${props.packData.id}/edit/`}
                style={{ textDecoration: "none" }}
              >
                <Button color="primary">Editar</Button>
              </Link>
            </ButtonGroup>
          </SectionHeader>
          <hr />
          <Flex justify="flex-start" gap="10rem">
            <PackDataContainer>
              <Bold>Nombre: </Bold>
              {props.packData.name}
              <Bold>Descripción: </Bold>
              {props.packData.description}
            </PackDataContainer>
            <PackDataContainer>
              <Bold>Activo: </Bold>
              {props.packData.active ? "Si" : "No"}
              <Bold>Stackable: </Bold>
              {props.packData.stackable ? "Si" : "No"}
            </PackDataContainer>
            <PackDataContainer>
              <Bold>Horas de ayuno: </Bold>
              {props.packData.fast_hours}
              <Bold>Tag: </Bold>
              {props.packData.tag}
            </PackDataContainer>
            {props.packData.external_reference && (
              <PackDataContainer>
                <Bold>External reference: </Bold>
                {props.packData.external_reference}
              </PackDataContainer>
            )}
          </Flex>
          <Flex justify="flex-start" gap="5rem">
            <PriceContainer>
              <>
                <Bold>Precio final: </Bold>{" "}
                {numberWithPeriods(props.packData.final_price)}
              </>
              <>
                <Bold>Precio original: </Bold>{" "}
                {numberWithPeriods(props.packData.strikethrough_price)}
              </>
            </PriceContainer>
          </Flex>
        </Flex>
      </SpacedPaper>
    </>
  );
};

export default PackData;
