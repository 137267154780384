import React, { useEffect, useState } from "react";
import packService from "../../utils/api/v1/packService";

// typings
import { CheckoutLabService } from "../../utils/interfaces/LabServices";

// Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Flex from "../Containers/Flex";


interface Props {
  id: string;
  name: string;
  labServiceId: string;
  packLabServices: CheckoutLabService[];
  setPackLabServices: React.Dispatch<React.SetStateAction<CheckoutLabService[]>>;
  setError: (error: any) => void;
}

const PackLabServiceCard = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const removePackService = async (): Promise<void> => {
    setLoading(true);
    try {
      await packService.removeServiceFromPack(
        props.id,
        {
          lab_services: [props.labServiceId],
        }
      );
      props.setPackLabServices(
        props.packLabServices.filter((service: CheckoutLabService) => service.id !== props.labServiceId)
      );
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    setLoading(false);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Flex>
          <Typography gutterBottom variant="h6" component="h3">
            {props.name}
          </Typography>
        </Flex>
        <Flex className="float-right">
          <Button
            variant="contained"
            color="secondary"
            className="float-right"
            disabled={loading}
            onClick={removePackService}
          >
            Eliminar <DeleteIcon />
          </Button>
        </Flex>
      </CardContent>
    </Card>
  );
};

export default PackLabServiceCard;
